import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../components/Layout'
import Img from "gatsby-image"
//add loadable here:
import FormEbook from '../../components/FormEbook'
import Helmet from 'react-helmet'

export default class Index extends React.Component {
  componentDidMount(){
    document.getElementById('lang-switch').href = 'https://e-multicontent.com/ebook/'
  }

  render() {
    const  { data } = this.props
    return (
      <>
      <Helmet titleTemplate="Ebook - Jak zwiększyć sprzedaż w e-sklepie w pięciu prostych krokach | e-multicontent.pl">
        <title>Ebook - Jak łatwo zwiększyć sprzedaż w e-sklepie?  | e-multicontent.pl</title>
        <meta name="description" content={'Zapisz się by otrzymać naszego darmowego e-booka. Jak zwiększyć sprzedaż w e-sklepie w pięciu prostych krokach. ✔️'}/>
        <meta name='image' content={'https://e-multicontent.pl/img/apple-touch-icon.png'} />
        <meta name='keywords' content={'e-book, copywriting, copywriterzy, marketing, agencja copywriterska, Wrocław, zespół copywriterów, praca, biznes, treści, content, tłumaczenia, SEO, blog, teksty, artykuły, grafika, video, strony internetowe, sklepy internetowe, marketplace, e-commerce'} />
        <meta property="og:description" content={'Zapisz się by otrzymać naszego darmowego e-booka. Jak zwiększyć sprzedaż w e-sklepie w pięciu prostych krokach. ✔️'}/>
        <meta property='og:title' content={'Ebook - Jak łatwo zwiększyć sprzedaż w e-sklepie?  | e-multicontent.pl'} />
        <meta property='og:image' content={'https://e-multicontent.pl/img/apple-touch-icon.png'} />
        <meta property="og:url" content={'https://e-multicontent.pl/ebook/'}/>

        <link rel="alternate" href="https://e-multicontent.com/ebook/" hrefLang="en-gb" />
        <link rel="alternate" href="https://e-multicontent.com/ebook/" hrefLang="en-us" />
        <link rel="canonical" href="https://e-multicontent.pl/ebook/"  />

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="e-multicontent.pl" />
        <meta property="twitter:url" content="https://e-multicontent.pl/ebook/" />
        <meta name="twitter:title" content="Ebook - Jak łatwo zwiększyć sprzedaż w e-sklepie?  | e-multicontent.pl" />
        <meta name="twitter:description" content="Zapisz się by otrzymać naszego darmowego e-booka. Jak zwiększyć sprzedaż w e-sklepie w pięciu prostych krokach. ✔️" />
        <meta name="twitter:image" content="https://e-multicontent.pl/img/apple-touch-icon.png" />

        <script type='application/ld+json'>
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [{
            "@type": "ListItem",
            "position": 1,
            "name": "Home",
            "item": "https://e-multicontent.pl/"
          },{
            "@type": "ListItem",
            "position": 2,
            "name": "Ebook",
            "item": "https://e-multicontent.pl/ebook/"
          }]
        })}
        </script>
      </Helmet>
      <Layout style={{backgroundColor:'rgba(255,255,255,0.0)'}}>
      <nav className="breadcrumbs-nav section">
          <Link className="inline-link" to="/">Home</Link>&nbsp;&gt;&nbsp;<span style={{fontWeight:'600',color:'white'}}><b>Ebook</b></span>
      </nav>
      <Img fluid={data.file.childImageSharp.fluid}
      className="career-image"
      loading={'eager'}
      alt="Ebook e-multicontent - copywriting, tłumaczenia, seo, strony internetowe, e-commerce, marketplace"
      style={{
        marginTop:'0px',
        width: '100%',
        position: 'absolute',
        minHeight: '900px',
        zIndex: '-1',
      }} />
        <section style={{backgroundColor:'rgba(0,0,0,.4)'}}>

        <div  style={{borderRadius:'10px',padding:'5px',width:'100%'}}>
        {/*message*/}
        <div className="container" id="ebook"  style={{backgroundColor:'rgba(0,0,0,0)',padding:'5%',margin:'auto'}}>
        <h1 className="title" style={{color:'white',fontSize:'2em',marginTop:'25px',marginLeft:'40px'}}>E-book</h1>
        {/*<iframe id="fm-fc-f-ozzh90n74h" src="https://forms.freshmail.io/f/70jqexgyfc/ozzh90n74h/index.html" title="ebook" frameBorder="0" marginHeight="0" marginWidth="0" width="100%" style={{minHeight: "600px"}}></iframe>*/}
        <FormEbook />
        </div>
        </div>

        </section>


        <div id="ebookbot" style={{paddingTop:'calc(5%)',position:'relative',paddingBottom:'50px',backgroundColor:'white'}} align="center">
        <br></br><br></br>
        <p style={{color:'#222222'}}><b>Chcesz wysłać maila?</b></p>
        <br></br>
        <p><a href="mailto:biuro@e-multicontent.pl" rel="noopener noreferrer" className="btn" target="_blank">biuro@e-multicontent.pl</a></p>
        <br></br>

        <p style={{color:'#222222'}}><b>Czy może wolisz porozmawiać?</b></p>
        <br></br>
        <p><a href="tel:+48717591140" rel="noopener noreferrer" className="btn" target="_blank">+48717591140</a></p>
        <br></br>




        <br></br>


        <h3 style={{color:'#29b1ff'}}>Złóż nam wizytę przy najbliższej okazji:</h3>
        <br></br>
        <h4 style={{color:'#222222'}}>Katarzyńska 1 F,<br></br> 55-010 Radwanice</h4>
        <sub>Biuro czynne w godzinach od <b>8 do 16</b> od <b>poniedziałku do piątku.</b></sub>
        <br></br>
        <br></br>
        <br className="column is-1 is-2 is-3 is-4 is-5 is-6 is-7 is-8 is-9 is-10 is-12"></br>
        <div>
        <Link align="center" className="btn" style={{zIndex:'10000'}} to="/"> Wróć na stronę. </Link>
        </div>
        </div>


      </Layout>
      </>
    )
  }

}

export const query = graphql`
  query FilesEbook{
    file(relativePath: {regex: "/optimized/ebooktlo.jpg/"}) {
      childImageSharp {
        fluid(fit: COVER) {
        srcWebp
        src
        srcSet
        srcSetWebp
      }
      }
    }
  }
`
